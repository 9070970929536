.closeCollaborator{
  display: flex;
  flex-direction: row;
  justify-content:flex-end;
  align-items: end;
  padding: 0 !important;   
  margin: 0 !important;
}

.MuiDialog-paperWidthSm{
    border-radius: 10px !important;
 }

 .containerMdalObjective
 {
    display: flex; 
    flex-direction: column;
    justify-content:flex-start;
    align-items: center;
    align-content:flex-start;
    width: 545px !important;    
    height: auto;
 }
 
 .titlePrestacion{    
     font-family: "Roboto", sans-serif !important;    
     font-weight: 700;
     font-size: 26px;    
     color:#093C5D ;
 }
 
 .oneDivObjective{
   display: flex;
   flex-flow: row nowrap;  
   justify-content:flex-start ;
   justify-content: flex-start;  
   align-items: baseline ;   
   width: 100% !important;    
   margin-top:5px; 
   margin-bottom: 5px;   
 }
 
 .twoInputsObjective{
     display: flex;
     flex-direction: row;  
     justify-content: space-between;  
     align-items: center ;         
     width: 100% !important;      
     height: auto;
     margin-top:5px; 
     margin-bottom: 5px;
     gap: 20px;
 }

 .selectCollaboratorsButton{
  background-color: #9DB1BE;
  width: 100%;
  height: 40px;
  border: none;
  border-radius: 6px;
  text-align: left;
  font-style: italic;
  cursor: pointer;    
}
 
 .twoBtnsObjectives{
    display: flex;
    flex-direction: row;  
    justify-content: center;  
    align-items: center;     
    padding: 0 !important;   
    width: 100% !important; 
    height: auto;
    gap: 20px;
    margin-bottom: 10px;
  }
  .selectLabel {
    background-color: white;
    font-style: normal;
  }
  .select {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    font-style: normal;
  }

  .cancelObjectives{
    padding: 10px 20px 10px 20px;
    border-radius: 6px;
    gap: 6px;
    border: 1px solid #c7ccdc;
    height: 40px;
    color: #093c5d;
    background: transparent;
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: 0px;
    width: 175px;
    cursor: pointer;
  }

 
 
 
 
 
 
 