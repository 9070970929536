.fp_contenedorPrincipal{
    background-color: #ffffff;
    height: auto;
    width: 95%;
    margin-left: 21px;
    border-radius: 12px;
    padding-top: 20px;
    min-width: 1104px !important;
    padding-bottom: 20px;
    box-sizing: border-box;
  }
  
  .fp_contenedorHeader{
    width: 100%;
    height: auto;
  }
  
  .fp_filaHeader{
    width: 98%;
    height: 50px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
  }

  .fp_filaHeader .spacer {
    flex-grow: 1; /* Este elemento ocupar� todo el espacio disponible */
  }
  
  .fp_contenedorCompania{
    font-family: 'Roboto', sans-serif;
    font-size: 24px;
    font-weight: 700;
    line-height: 28px;
    letter-spacing: 0.66px;
    width: auto;
    height: auto;
    margin-left: 20px;
  }
  
  .fp_contenedorSeleccionEmpresa{
    width: auto;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap:20px;
  }
  
  .fp_contenedorFiltro{
    display: flex;
    align-items: center;
    margin-left: 20px;
    width: 320px;
    height: 38px;
    border: 1px solid #c7ccdc;
    border-radius: 8px;
  }
  
  .pf_inputBuscador{
    display: flex;
    align-items: center;
    width: 292px;
    min-width: 292px;
    box-sizing: border-box;
    border-radius: 8px;
    padding-left: 15px;
    height: 38px;
    border: none;
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    font-size: 13px;
    line-height: 15px;
    color: #303030;
  }
  
  .pf_inputBuscador::placeholder{
    font-family: 'Roboto', sans-serif;
    font-style: italic;
    font-weight: 500;
    font-size: 13px;
    line-height: 15px;
    color: #303030;
    opacity: 0.6;
  }
  
  .pf_inputBuscador:focus{
    border: none !important;
    outline: none;
  }
  
  .pf_inputBuscador:active{
    border: none !important;
    outline: none;
  }
  
  .fp_iconoBuscador{
    background-image: url('/src/assets/svg/icono_buscar_dos.svg');
    width: 18.12px;
    height: 18.12px;
  }
  
  .fp_selectEmpresa{
    width: 170px;
    height: 25px;
    border: none;
    border-radius: 8px;
    background-color: #ffffff;
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    font-size: 14px;
    line-height: 15px;
    color: #303030;
    padding-left: 15px;
  
  }
  
  .fp_contenedorCuenta{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap:20px;
  }
  
  .fp_contenedorTituloEmpresaInterno{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: auto;
    height: 50px;
    color: #303030;
    font-family: 'Roboto', sans-serif;
    font-weight: 700;
    font-size: 16px;
    line-height: 18.75px;
    letter-spacing: 0.44px;
  }
  
  .fp_contenedorTituloCuentaBancaria{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: auto;
    height: 50px;
    color: #303030;
    font-family: 'Roboto', sans-serif;
    font-weight: 700;
    font-size: 16px;
    line-height: 18.75px;
    letter-spacing: 0.44px;
  }
  
  .fp_contenedorInternoCuenta{
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    font-size: 13px;
    line-height: 15px;
    letter-spacing: 0.36px;
    width: 155px;
    height: 25px;
    background-color: #e9ebf1;
    border-radius: 8px;
    border: solid 1px #c7ccdc;
    margin-top: 3px;
  }
  
  .fp_iconoDescargar{
    background-image: url('/src/assets/svg/icono_descargar_reporte.svg');
    width: 9.33px;
    height: 11.33px;
    margin-right: 10.83px;
  }
  
  .fp_botonDescargar{
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    background-color: #fabb00;
    border-radius: 6px;
    box-shadow: 0px 2px 8px rgba(250,187,0,0.4);
    width: 155px;
    font-family: 'Roboto', sans-serif;
    font-weight: 700;
    font-size: 13px;
    line-height: 15px;
    letter-spacing: 0.36px;
    color: #093c5d;
    height: 30px;
    cursor: pointer;
  }
  
  .fp_contenedorExportaReporte{
    height: auto;
    width: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 15px;
  }
  
  .fp_textoSaldoCuenta{
    font-family: 'Roboto', sans-serif;
    font-weight: 700;
    font-size: 16px;
    color:#303030;
    line-height: 18.75px;
    letter-spacing: 0.44px;
  }
  
  .fp_contenedorInternoSaldo{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .fp_contenedorSaldo{
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    font-size: 13px;
    line-height: 15px;
    letter-spacing: 0.36px;
    width: 155px;
    height: 25px;
    background-color: #e9ebf1;
    border-radius: 8px;
    border: solid 1px #c7ccdc;
    margin-top: 3px;
  }
  
  .fp_contenedorBotonDescargar{
    width: auto;
    height: 50px;
    display: flex;
    justify-content: end;
    flex-direction: column;
  }
  
  .fp_contenedorTablaPrincipal{
    margin-top: 26px;
  }
  
  .fp_tablaPrincipal{
    width: 96%;
    margin-left: 29px;
    border-collapse: collapse;
    border-radius: 10px !important;
  }
  
  .pf_contenedorTitulos{
    background-color: #e9ebf1;
    height: 32px;
    border-radius: 10px !important;
  }
  
  .pf_primerColumnaTitulo{
    padding-left: 24px;
  }
  
  .pf_columnaTitulos{
    text-align: start;
    width: calc(100% / 8);
    font-family: 'Roboto', sans-serif;
    font-weight: 700;
    font-size: 13px;
    line-height: 15px;
    letter-spacing: 0.36px;
    color: #303030;
  }
  
  .pf_columnaDatos{
    background-color: #ffffff;
    border-bottom: solid 1px #c7ccdc;
  }
  
  .pf_contenedorTitulosDatos{
    height: 32px;
  }
  
  .pf_borderPrimeraColumna{
    border-bottom: solid 1px #eaecf2;
    opacity: 0.8;
  }
  
  .pf_columnaDatos{
    background-color: #ffffff;
    border-bottom: solid 1px #c7ccdc;
    font-family: 'Roboto', sans-serif;
    color: #303030;
    opacity: 0.8;
    font-weight: 400;
    font-size: 13px;
    line-height: 15px;
    letter-spacing: 0.36px;
    height: 37px;
  }
  
  /*Paginacion*/
  .contenedorPaginacion{
    display: flex;
    width: 96%;
    justify-content: space-between;
    padding-top: 18px;
    padding-top: 18px;
    height: 20px;
    margin-left: 26px;
  }
  
  .columnaUnoPaginacion{
    font-family: 'Roboto';
    font-weight: 400;
    font-size: 13px;
    color: #303030;
    opacity: 0.8;
  }
  
  .columnaDosPaginacion{
    display: flex;
    justify-content: center;
  }
  
  .textoPaginacion{
    font-family: 'Roboto';
    font-weight: 400;
    font-size: 13px;
    color: #303030;
    opacity: 0.8;
  }
  
  .columnaInternaPaginacion{
    display: flex;
    align-items: center;
  }
  .columnaBotonesPaginacion{
    display: flex;
    align-items: center;
    gap: 8px;
    margin-left: 5px;
  }
  
  .contenedorListaElementos{
    width: 20px;
    height: auto;
    border-radius: 4px;
    margin-left: -15px;
  }
  
  .iconoPaginacion{
    background-image: url('/src/assets/svg/icono_paginacion_flecha.svg');
    width: 7px;
    height:4px;
    margin-left: 7.77px;
    margin-right: 17.23px;
  }
  
  .iconoPaginacionSiguienteActivo{
    background-image: url('../../assets/svg/icono_siguiente_activo.svg');
    width: 16px;
    height: 16px;
  }
  
  .iconoPaginacionSiguienteInactivo{
    background-image: url('../../assets/svg/icono_siguiente_inactivo.svg');
    width: 16px;
    height: 16px;
  }
  
  .iconoPaginacionAnteriorActivo{
    background-image: url('../../assets/svg/icono_siguiente_activo.svg');
    width: 16px;
    height: 16px;
    transform: rotate(180deg);
  }
  
  .iconoPaginacionAnteriorInactivo{
    background-image: url('../../assets/svg/icono_siguiente_inactivo.svg');
    width: 16px;
    height: 16px;
    transform: rotate(180deg);
  }
  
  .listaElementos{
    list-style: none;
    padding: 0;
    text-align: center;
  }
  
  .listaElementos > li{
    border: 1px solid #C7CCDC;
    font-family: 'Roboto';
    font-weight: 400;
    font-size: 13px;
    color: #303030;
    opacity: 0.8;
  }
  
  .listaElementos > li:hover{
    background-color: #C7CCDC;
    border: 1px solid #C7CCDC;
    font-family: 'Roboto';
    font-weight: 400;
    font-size: 13px;
    color: #303030;
    opacity: 0.8;
  }
  