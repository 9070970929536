.containerInfo{
    display: flex;
    flex-direction:row;
    justify-content:space-between;
    align-items:center ;
    width: 100%;   
}

.txtInfo{
width: 100%;
font-family: Roboto;
font-size: 16px;
font-weight: 500;
line-height: 19px;
letter-spacing: 0.4375px;
text-align: left;
}

.conteinerCampo{
  padding: 10px 6px 10px 20px;
  font-family: Roboto;
  font-weight:400;
  color: rgba(0,0, 0, 0.87);
  border-bottom: 1px solid rgba(0,0,0, 0.12);
  display: flex;
  justify-content: space-between;
}