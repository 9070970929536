@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap');
.contenedorCerrarModal{
  width: 499px;
  height: auto;
  display: flex;
  justify-content: flex-end;
  padding-top: 21.17px;
  box-sizing: border-box;
}

.cerrarModal{
  background-image: url('/src/assets/svg/icono_cerrar_modal.svg');
  width: 13.18px;
  height: 13.22px;
  margin-right: 24.33px;
  cursor: pointer;
}

.contenedorPrincipal{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: auto;
  margin-top: 16.75px;
}

.iconoEliminar{
  background-image: url('/src/assets/svg/icono_modal_eliminar.svg');
  width: 104.17px;
  height: 104.17px;
}

.em_container{
  width: 499px;
  height: auto;
  box-sizing: border-box;
}

.em_titulo{
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  color: #093c5d;
  margin-top: 12.91px;
  margin-bottom: 19.5px;
}

.em_mensajePrtincipal{
  font-family: 'Roboto', sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 18px;
  color: #222222;
  opacity: 0.8;
  text-align: center;
  line-height: 20px;
}
.emm_mensajePrtincipal{
  font-family: 'Roboto', sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 18px;
  color: #222222;
  opacity: 0.8;
  text-align: center;
  line-height: 20px;
  margin-top: 15px;
}

.input{
  width: 187.52px;
  height: 32.09px;
  border: 0.8px solid #c7ccdc;
  margin-top: 6.41px;
  border-radius: 4px;
  text-align: center;
  font-size: 15px;
  color: #222222;
  opacity: 0.8;
}

.em_marginTop{
  margin-top: 10px;
}

.em_contenedorBotones{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 34.5px;
  margin-bottom: 34px;
}

.em_botonCancelar{
  width: 130px;
  height: 40px;
  border: 0.8px solid #e1e1e1;
  background-color: #fff;
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #093c5d;
  border-radius: 8px;
  cursor: pointer;
  margin-right: 25px;
}

.em_iconoConfirmarEliminar{
  background-image: url('/src/assets/svg/icono_confirmar_eliminar.svg');
  width: 13px;
  height: 17px;
  margin-right: 4.2px;
}

.em_botonConfirmarActivo{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 130px;
  height: 40px;
  border: none;
  border-radius: 8px;
  background: #FBE7E7;
  cursor: pointer;
}

.em_botonConfirmarAction{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 130px;
  height: 40px;
  border: none;
  border-radius: 8px;
  background: #FABB00;
  cursor: pointer;
}

.em_botonConfirmarInactivo{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 130px;
  height: 40px;
  border: none;
  border-radius: 8px;
  background: #FBE7E7;
  cursor: no-drop;
  opacity: 0.5;
}

.fim_container{
  width: 662px;
  max-width: 662px;
}

.fim_contenedorCerrarModal{
  display: flex;
  justify-content: flex-end; 
  width: 662px;
  padding-top: 21.19px;
}

.fim_contenedorIconoPrincipal{
  width: 100%;
  display: flex;
  justify-content: center;
  height: auto;
  margin-top: 11.39px;
}

.fim_iconoPrincipal{
  background-image: url('/src/assets/svg/icono_modal_atencion.svg');
  width: 93px;
  height: 93px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.fim_iconoComplemento{
  background-image: url('/src/assets/svg/complemento_atenncion.svg');
  width: 11.07px;
  height: 50.31px;
}

.tituloAtencion{
  text-align: center;
  font-size: 26px;
  font-weight: 700;
  line-height: 30px;
  letter-spacing: 0.5px;
  color: #093C5D;
  margin-top: 20.2px;
  margin-bottom: 19.5px;
}

.mensajeAtencion{
  text-align: center;
  font-size: 16px;
  margin-top: 19.5px;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  line-height: 18px;
  color: #222222;
}

.contenedorBotonesAtencion{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 40.46px;
  margin-top: 35.07px;
}

.botonesAtencion{
 border-radius: 8px !important;
 width: 130px;
 height: 40px;
 font-family: 'Roboto', sans-serif;
 font-weight: 500;
 font-size: 14px;
 line-height: 16px;
 border-radius: 4px;
 color: #093C5D;
 cursor: pointer;
 -webkit-border-radius: 8px !important;
 -moz-border-radius: 8px !important;
 -ms-border-radius: 8px !important;
 -o-border-radius: 8px !important;
}

.cerrarAtencion{
  background-color: #ffffff;
  border: 0.8px solid #e1e1e1;
  margin-right: 32px;
}

.continuarAtencion{
  background-color: #fabb00;
  border:none;
}

.continuarAtencionInactivo{
  background-color: #fabb00;
  border:none;
  opacity: 0.5;
  cursor: no-drop;
}

.ad_contenedor{
  width: 662px;
  max-width: 662px;
}

.ad_textoPrinipal{
  width: 400px;
  align-items: center;
}

.contenedorLoadingDispersion{
  width: 100px;
  min-height: 100px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loading{
  margin-top: 10px;
  background-image: url('../../../assets/loading.gif');
  width: 100px;
  height: 100px;
  background-size: cover;
}


.em_espacioentreTexto{
  margin-top: 5px;
}

.em_textoNegritas{
  font-weight: 700;
}

/*Contenedor Outsourcers*/

.em_contenedorPrincipalOutsourcers{
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.em_titulosTablaOutsourcers{
  width: 437.38px;
  height: 47px;
  border-radius: 12px;
  background-color: #e5e9eb;
  display: flex;
  justify-content: center;
  align-items: center;
}

.em_titulosTablaOutsourcers > div{
  width: 33.3%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Roboto';
  font-weight: 700;
  font-size: 13px;
  color: #303030;
}


.em_tablaPrincipalOutsourcers{
  width: 437.38px;
  height: 50px;
  border-radius: 12px;
  margin-top: 12px;
  display: flex;
  border: solid 1px #c7ccdc;
}

.em_tablaPrincipalOutsourcers > div{
  width: 33.3%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Roboto';
  font-weight: 400;
  font-size: 12px;
  color: #303030;
}

.em_tablaPrincipalOutsourcers > div:first-child{
  border-right: solid 1px #c7ccdc;
}


.em_tablaPrincipalOutsourcers > div:nth-child(2){
  border-right: solid 1px #c7ccdc;
}

.em_tabla{
  width: 437.38px;
  height: 50px;
  border-radius: 12px;
  margin-top: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.em_botonModalOutsourcers{
  width: 130px;
  height: 40px;
  border: solid 1px #e1e1e1;
  border-radius: 4px;
  background-color: #ffffff;
}

.em_tablaBotonOutsourcers{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

.pd_botonDispersarDos{
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  background-color: #fabb00;
  border-radius: 6px;
  box-shadow: 0px 2px 8px rgba(250, 187, 0, 0.4);
  margin-left: 24px;
  margin-right: 24px;
  width: 115px;
  font-family: 'Roboto', sans-serif;
  font-weight: 700;
  font-size: 13px;
  line-height: 15px;
  letter-spacing: 0.36px;
  color: #093c5d;
  height: 32px;
  cursor: pointer;
}


.pc_iconoDispersar{
  background-image: url('/src/assets/svg/icono_derecha_sin.svg');
  width: 4.71px;
  height: 8px;
  margin-left: 10.5px;
}


.em_selectOutsourcers{
  margin-top: 20px;
  margin-bottom: 20px;
  width: auto;
  min-width: 200px;
  height: 42px;
  border: 1px solid #c7ccdc !important;
  font-family: 'Roboto';
  font-weight: 400;
  font-size: 11px;
  line-height: 13px;
  letter-spacing: 0.36px;
  color: #303030;
  padding-left: 17px;
  background-color: #fff;
}

.em_contenedorSelectOutsourcers{
  display: flex;
  justify-content: center;
}

.pdd_contenedor{
  width: 170px;
  margin-bottom: 15px;
}