

.pc_contenedorTabla{
  /* background-color: #e9ebf1; */
  width:auto ;  
  height:500px ;
  overflow-x: auto;
  white-space: nowrap;
}

.pc_tablaPrincipal{
  width:auto ;
  border-collapse: collapse
}

.pd_contenedorTitulos{
  background-color: #e9ebf1;
  height: 32px;
  border-radius: 10px !important;
}

.checkboxitem {
  text-align: left;
}

.pc_columnaTitulos{
  min-width: auto;  
  font-family: 'Roboto', sans-serif;
  font-size: 13px;
  font-weight: 700;
  color: #303030;
  line-height: 15px;
  letter-spacing: 0.36px;
}

.pc_columnaDatos{
  background-color: #fff ;
  border-bottom: solid 1px #c7ccdc;
  font-family: 'Roboto', sans-serif;
  color: #303030;
  opacity: 0.8;
  font-weight: 400;
  font-size: 13px;
  line-height: 15px;
  letter-spacing: 0.36px;
  height: 37px;
}

.pc_bordePrimeraColumna{
  border-bottom: solid 1px #eaecf2;
  opacity: 0.8;
}

.checkboxitem input[type="checkbox"]:checked + label:after {
  width: 14px;
  height: 14px;
  position: absolute;
  top: 3px;
  left: 3px;
  content: " ";
  display: block;
  background: #fabb00;
}

.pd_botonEstatus{
  width: 113px;
  height: 24px;
  border-radius: 8px;
  cursor: pointer;
}

.pd_botonEstatusDos{
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f8ea64;
}

.pd_textoBotonEstatusDos{
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  line-height: 11px;
  letter-spacing: 0.25px;
  color: #093c5d;
  font-size: 9px;
}

.pd_botonEstatusCuatro{
  display: flex;
  align-items: center;
  background-color: #11CA73;
}

.pd_botonEstatusError{
  display: flex;
  align-items: center;
  background-color: red;
}

.pd_textoBotonEstatusCuatro{
  font-family: 'Roboto', sans-serif;
  font-size: 9px;
  font-weight: 500;
  line-height: 11px;
  letter-spacing: 0.25px;
  color: #ffffff;
  margin-left: 35px;
}

.pd_iconoEstatusCuatro{
  background-image: url('/src/assets/svg/icono_palominta.svg');
  width: 9.5px;
  height: 8px;
  margin-left: 16px;
}


